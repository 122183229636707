<template>
  <div
    :class="
      `${type} h-20 rounded border-dotted border-2 border-gray-300 items-center justify-center flex cursor-pointer`
    "
    style="background:#f3f3f3;"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
    @click="onClickUpload"
  >
    <div
      v-if="idx != -1"
      :class="current || this.previewAvatar != null ? 'w-full h-full' : ''"
    >
      <img
        v-if="current && !previewAvatar"
        :src="current"
        class="object-cover w-full h-full"
      />
      <img
        v-if="previewAvatar"
        :src="previewAvatar"
        class="object-cover w-full h-full"
      />
    </div>

    <input
      type="file"
      class="absolute w-px h-px overflow-hidden opacity-0"
      @change="onChange"
      ref="file"
      accept=".png, .jpg"
    />
    <div class="grid" v-if="previewAvatar == null && current == null">
      <i class="text-lg text-center fas fa-cloud-upload-alt"></i>
      <span class="drag-upload">{{ title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadImage',
  data: function() {
    return {
      file: null,
      previewAvatar: null,
    }
  },
  props: {
    value: {
      required: false,
    },
    current: {
      required: false,
    },
    title: {
      required: false,
      default: 'Drag or Upload Front',
    },
    // depricated -> use vee validate
    errors: {
      required: false,
      default: '',
    },
    // deprecated -> will be renamed to size
    type: {
      requried: false,
      default: 'w-20',
    },
    // deprecated -> unknown usage
    idx: {
      required: false,
      default: -1,
    },
  },
  created() {
    console.log('this.errors = ', this.errors)
  },
  methods: {
    onChange() {
      let input = this.$refs.file
      let file = input.files

      if (file && file[0]) {
        let reader = new FileReader()
        reader.onload = (e) => {
          this.$emit('preview-image', e.target.result)
          if (this.idx != -1) this.previewAvatar = e.target.result
          else this.$emit('pre_input', e.target.result)
        }
        reader.readAsDataURL(file[0])
        if (this.idx != -1) this.$emit('input', file[0], this.idx)
        else this.$emit('input', file[0])
      }
    },
    remove(i) {
      this.filelist.splice(i, 1)
    },
    dragover(event) {
      console.log('dragover = ', event)
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100')
        event.currentTarget.classList.add('bg-green-300')
      }
    },
    dragleave(event) {
      // Clean up
      console.log('dragleave = ', event)
      event.currentTarget.classList.add('bg-gray-100')
      event.currentTarget.classList.remove('bg-green-300')
    },
    drop(event) {
      console.log('drop = ', event)
      event.preventDefault()
      this.$refs.file.files = event.dataTransfer.files
      this.onChange() // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add('bg-gray-100')
      event.currentTarget.classList.remove('bg-green-300')
    },
    onClickUpload() {
      this.$refs.file.click()
    },
  },
}
</script>

<style lang="scss" scoped>
.file-icon {
  width: 55px;
  height: 65px;
}
.upload-button {
  width: 135px;
  height: 35px;
  margin-top: 10px;
  background: #eaeaea;
  border-radius: 5px;
  span {
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}
.drag-upload {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #87888e;
}
</style>
